import api from './http'
//商品分类
const showInfoCreate =(data)=>api.post('/sign',data)
const getOpenidHandler = (data) =>api.get(`/getOpenId/${data}`)
const getTickHandler = () =>api.get(`/getPrice`)
const wxPayHandler = (data) =>api.post(`/preOrder`,data)
export default{
    showInfoCreate,
  getOpenidHandler,
  wxPayHandler,
  getTickHandler,
}
